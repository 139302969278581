$default-font: "PT Sans", sans-serif;
$blue: #009fc9;
$menu: #ebf3fc;
$text2: #5d93cf;
$subtitle: #ff623e;
$bg1: #f8f6f2;
$hover: #5576b1;
$text3: #66daf8;
$green: #66ce6e;
$text: #0a2e6e;
$bg: #3f67af;
