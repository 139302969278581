@import "fonts";
@import "vars";

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  &:before, &:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; } }

*::-webkit-input-placeholder {
  color: #666;
  opacity: 1; }

*:-moz-placeholder {
  color: #666;
  opacity: 1; }

*::-moz-placeholder {
  color: #666;
  opacity: 1; }

*:-ms-input-placeholder {
  color: #666;
  opacity: 1; }

body input:focus:required:invalid,
body textarea:focus:required:invalid {
  color: #666; }

body input:required:valid,
body textarea:required:valid {
  color: #666; }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

body {
  font-size: 18px;
  min-width: 320px;
  position: relative;
  line-height: normal;
  font-family: $default-font; }


body, html {
  overflow-x: hidden; }

img {
  display: block;
  max-width: 100%;
  height: auto; }

a {
  display: block;
  transition: .3s ease;
  text-decoration: none;
  outline: none;
  &:hover {
    text-decoration: none; } }

button {
  outline: none;
  box-shadow: none;
  border: none;
  transition: .3s ease;
  cursor: pointer;
  &:hover, &:focus {
    opacity: 1; } }

input, select, textarea {
  outline: none;
  box-shadow: none;
  transition: .3s ease;
  border: none; }

.text-center {
  text-align: center; }

.container {
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  max-width: 1140px;
  padding: 0 15px;
  box-sizing: border-box; }

.text-blue {
  color: $blue; }

.text-orange {
  color: $subtitle; }

.icon-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95px;
  height: 95px;
  min-width: 95px;
  border-radius: 20px;
  background-color: $menu;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 16px;
    height: 32px;
    background: url("../img/arrow-right.png") no-repeat center {
      size: cover; } } }

.logo {
  display: flex;
  align-items: center;
  .icon {
    width: 55px;
    margin: 0 22px 0 0; }
  .text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-family: 'BanderaPro-Bold', sans-serif;
    .title {

      font-size: 20px;
      color: $text; }
    .description {
      color: $bg;
      font-size: 18px; } } }

.btn-all {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px 50px;
  border-radius: 10px;
  background-color: $menu;
  color: $text2;
  font-size: 14px;
  font-family: 'BanderaProMedium', sans-serif;
  &:hover {
    background: $text2;
    color: #fff; } }

.btn-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 30px 16px 29px;
  border-radius: 10px;
  background-color: #eef0f5;
  width: 320px;
  color: $text;
  font-family: 'BanderaProMedium', sans-serif;
  font-size: 16px;
  cursor: pointer;
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    span {
      display: block;
      width: 14px;
      height: 3px;
      border-radius: 2px;
      background-color: #00a0cb;
      margin: 1.5px 0; } } }

.form-search {
  width: 350px;
  height: 50px;
  position: relative;
  button {
    width: 20px;
    height: 20px;
    background: transparent;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15px;
    right: 30px;
    z-index: 2; }
  label {
    width: 100%;
    height: 100%;
    z-index: 1;
    input {
      width: 100%;
      height: 100%;
      border: 1px solid #e3e6ee {
        radius: 10px; }
      background-color: #ffffff;
      font-size: 16px;
      color: rgba($text, .3);
      padding: 0 30px;
      box-sizing: border-box;
      &::placeholder {
        color: rgba($text, .3); } } } }


//header
header {
  margin: 0 0 50px; }

.header-top, .header-bottom {
  width: 100%;
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between; } }

.header-top {
  padding: 39px 0; }

.nav-menu {
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 10px;
  background-color: $bg;
  padding: 0 30px; }

.main-menu {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  .list-item {
    position: relative;
    &:hover {
      .dropdown-menu {
        pointer-events: auto;
        opacity: 1;
        visibility: visible; } }
    .list-item-link {
      margin: 0 30px 0 0;
      color: $menu;
      font-family: 'BanderaProMedium', sans-serif;
      padding: 15px 0 16px;
      display: flex;
      align-items: center;
      .icon {
        width: 25px;
        margin: 0 14px 0 0; } } } }

.dropdown-menu-heading {
  position: absolute;
  left: 0;
  top: 50px;
  background-color: #eef0f5;
  width: 100%;
  z-index: 5;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  .list-menu {
    li {
      a {
        width: 100%;
        padding: 10px 15px;
        z-index: 14;
        font-family: 'BanderaProMedium', sans-serif;
        color: #0a2e6e;
        border-bottom: 1px solid #fff;
        &:hover {
          background: $text2;
          color: #333; } } } } }

.box-category {
  position: relative;
  &:hover {
    .dropdown-menu-heading {
      pointer-events: auto;
      opacity: 1;
      visibility: visible; } } }

.dropdown-menu {
  position: absolute;
  padding: 30px 20px;
  border-radius: 10px;
  width: 100%;
  min-width: 1000px;
  left: 0;
  top: 50px;
  background-color: #eef0f5;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  opacity: 0;
  visibility: hidden;
  transition: .3s ease;
  pointer-events: none;
  z-index: 7;
  li {
    min-width: 200px;
    margin: 0 0 10px;
    a {
      color: $text;
      font-family: 'BanderaProMedium', sans-serif;
      font-size: 16px;
      &:hover {
        text-decoration: underline; } } } }


//section main
.main {
  position: relative;
  background: url("../img/bg.png") no-repeat center {
    size: cover; }
  .container {
    align-items: flex-start; } }

.main-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: calc(100% - 350px);
  padding: 0 89px 0 0;
  box-sizing: border-box;
  .title {
    font-size: 18px;
    font-family: 'BanderaPro-Bold', sans-serif;
    padding: 0 0 5px;
    border-bottom: 2px solid #009ECB;
    margin: 0 0 48px; }
  p {
    color: $text; } }

.article-title-box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 0 60px;
  .links-title {
    color: $text;
    font-family: 'Lora', sans-serif;
    font-size: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    margin: 0 0 25px;
    &:hover {
      text-decoration: underline;
      color: $hover; } }
  .icon-arrow {
    margin: 0 0 0 58px; } }

.info-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap; }

.position {
  max-width: 225px;
  font-size: 12px;
  color: rgba(#0a2e6e, .5);
  margin: 0 46px 0 0; }

.time {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: rgba(#0a2e6e, .5);
  padding: 0 0 0 25px;
  box-sizing: border-box;
  background: url("../img/clock.png") no-repeat left {
    size: 15px; } }

.info-author {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 50px 0 0;
  .photo {
    overflow: hidden;
    margin: 0 15px 0 0;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    min-width: 30px; }
  .text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 11px;
    p {
      color: $text;
      opacity: 0.7; } } }

.view-item {
  display: flex;
  align-items: center;
  color: $text;
  opacity: .5;
  font-size: 12px;
  padding: 0 0 0 27px;
  background: url("../img/view.png") no-repeat left {
    size: 18px; } }

.article-list {
  width: 100%;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  justify-content: space-between;
  .line {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 50px;
    border-bottom: 1px solid #e0e7f0;
    &:last-child {
      margin-bottom: 0;
      border: none; } } }

.article-preview {
  width: 47%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 0 50px;
  .title {
    color: $text;
    font-size: 18px;
    font-family: 'Lora', sans-serif;
    margin: 0 0 26px;
    border: none;
    padding: 0;
    &:hover {
      color: $hover;
      text-decoration: underline; } }
  .heading-name {
    font-size: 14px;
    color: $blue; } }



//sidebar
.sidebar {
  width: 350px;
  .title {
    font-family: 'BanderaPro-Bold', sans-serif;
    padding: 0 0 5px;
    border-bottom: 2px solid $subtitle;
    margin: 0 0 50px;
    width: fit-content; }
  .our-books {
    .list-book {
      li {
        margin: 0 0 30px; } } } }


.list-hotter {
  li {
    position: relative;
    padding: 0 0 0 41px;
    margin: 0 0 47px;
    &:last-child {
      margin: 0; }
    &:hover {
      a {
        text-decoration: underline;
        color: $hover; }
      &:before {
        background: url("../img/fire-active.png") no-repeat center {
          size: contain; } } }
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      background: url("../img/fire.png") no-repeat center {
        size: contain; }
      width: 12px;
      height: 15px; }
    a {
      color: $text;
      font-size: 16px; } } }


//form subscribe
.callback {
  margin: 60px 0;
  .container {
    background-color: $bg;
    border-radius: 20px;
    padding: 34px 50px 30px 53px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      width: 70px;
      height: 62px;
      background: url("../img/icon7.png") no-repeat center {
        size: contain; } }
    .box-left {
      font-family: 'BanderaPro-Bold', sans-serif; }
    .sing-up {
      color: $text3;
      font-size: 18px; }

    .title {
      color: #fff;
      font-size: 30px;
      margin: 12px 0 8px; }
    .total {
      font-size: 18px;
      color: $green; }
    .box-right {
      p {
        font-size: 18px;
        color: $text3;
        font-family: 'BanderaPro-Bold', sans-serif;
        text-align: center; } } } }

#subscr-form-391 input[type='submit'] {
  background: #60dafa;
  color: $text;
  box-shadow: none;
  border: none;
  font-size: 14px;
  padding: 0;
  width: 150px;
  font-family: 'BanderaProMedium', sans-serif;
  border-radius: 0 10px 10px 0;
  &:hover {
    background: $text;
    color: $blue; } }

#subscr-form-391 input[type=text] {
  color: $text;
  font-size: 14px;
  background: url("../img/mail.png") no-repeat 30px center #ffffff {
    size: 9px; }
  border-radius: 10px 0 0 10px;
  height: 50px;
  width: 100%;
  border: none;
  margin: 0;
  padding: 0 150px 0 54px;
  box-sizing: border-box;
  &::placeholder {
    color: $text; } }

#subscr-block-391 {
  width: 400px;
  margin: 0 auto;
  background: transparent;
  form {
    background: transparent; }
  table {
    width: 100%;
    border: none;
    background: transparent;
    margin: 0 0 19px;
    position: relative;
    tr {
      &:last-child {
        position: absolute;
        right: 0;
        top: 0; } } }
  input {
    width: 100%; } }

.our-books, .article-news {
  margin: 0 0 70px;
  .container {
    flex-direction: column;
    align-items: flex-start; }
  .title {
    color: $text2;
    font-size: 18px;
    font-family: 'BanderaPro-Bold', sans-serif;
    padding: 0 0 5px;
    border-bottom: 3px solid $text2; }
  .article-preview {
    .title {
      border: none;
      padding: 0;
      color: $text;
      font-size: 18px;
      font-family: 'Lora', sans-serif; } } }

.list-book {
  margin: 41px 0 0 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  li {
    a {
      width: 350px;
      box-shadow: 0 0 32px rgba(137, 158, 178, 0.17);
      border-radius: 10px;
      background-color: #ffffff;
      padding: 30px 30px 30px 30px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: $text;
      font-size: 16px;
      font-family: 'Lora', sans-serif;
      &:hover {
        background: $bg1; }
      .icon {
        margin: 0 15px 0 0;
        width: 40px;
        min-width: 40px; }
      .icon-arrow {
        width: 45px;
        min-width: 45px;
        height: 45px;
        margin: 0 0 0 auto;
        border-radius: 10px;
        &:before {
          width: 8px;
          height: 17px; } } } } }

.article-news__wrapper {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 50px 0 0;
  justify-content: space-between;
  .article-title-box {
    width: 45%; } }

.article-news {
  .container {
    .btn-all {
      margin: 0 auto; } } }


//footer
footer {
  padding: 49px 0;
  background: #3D65B1;
  .container {
    justify-content: space-between;
    align-items: flex-start;
    .logo {
      .text {
        .title {
          color: #ffffff; }
        .description {
          color: #c8daef; } } } } }

.copyright {
  color: #ffffff;
  opacity: .5;
  font-size: 12px;
  margin: 30px 0 0 0;
  max-width: 241px;
  p {
    margin: 0 0 20px;
    &:last-child {
      margin: 0; } } }

.footer-box {
  max-width: 400px;
  .menu {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 0 0 50px 0;
    li {
      width: 50%;
      a {
        color: #c8daef;
        font-size: 14px;
        margin: 0 0 12px;
        &:hover {
          color: $blue; } } } }
  .form-search {
    width: 100%;
    label {
      input {
        background: transparent;
        color: #c8daef;
        border-color: #c8daef;
        font-size: 14px;
        &::placeholder {
          color: #c8daef; } } } } }

.box-social {
  max-width: 211px;
  p {
    opacity: 0.5;
    color: #fff;
    font-size: 12px;
    margin: 0 0 18px; } }

.list-social {
  display: flex;
  align-items: center;
  li {
    margin: 0 5px 0 0;
    &:last-child {
      margin: 0;
      a {
        img {
          margin: 0 5px 0 0; } } }
    a {
      font-size: 12px;
      color: rgba(#fff, .5);
      display: flex;
      align-items: center; } } }

.page-article {
  background: #fff;
  .main-content {
    padding: 0 30px 0 0; } }

.breadcrumb {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 0 10px;
  li {
    &:first-child {
      a {
        padding: 0 12px 0 21px;
        &:before {
          background: url("../img/home.png") no-repeat center {
            size: contain; }
          width: 11px;
          height: 9px; } } }
    a {
      color: #3f67af;
      font-size: 14px;
      position: relative;
      padding: 0 20px;
      &:hover {
        color: $blue;
        text-decoration: underline; }
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(0,-50%);
        width: 6px;
        height: 7px;
        background: url('../img/arrow.png') no-repeat center {
          size: contain; } } } } }

.main-title {
  font-size: 36px;
  font-family: 'Lora', sans-serif;
  color: #0a2e6e;
  margin: 0 0 30px; }

.description-article {
  margin: 36px 0 0 0;
  p {
    font-size: 18px;
    color: #0a2e6e;
    margin: 0 0 15px; } }


.content-article {
  margin: 40px 0 30px;
  background: #ebf3fc;
  border-radius: 20px;
  padding: 26px;
  box-sizing: border-box;
  .title {
    font: {
      size: 24px;
      family: 'Lora', sans-serif; }
    color: #0a2e6e;
    margin: 0 0 17px;
    width: fit-content;
    border: none;
    padding: 0; } }

.nav-content {
  padding: 0 0 0 16px;
  box-sizing: border-box;
  li {
    a {
      padding: 0 0 0 25px;
      position: relative;
      margin: 0 0 15px;
      text-decoration: underline;
      color: $bg;
      &:hover, &:focus {
        text-decoration: none;
        color: $text; }
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(0,-50%);
        width: 5px;
        height: 7px;
        background: url("../img/arrow-right-blue.png") no-repeat center {
          size: contain; } } } } }

.sub-drop {
  display: none;
  padding: 0 0 0 23px;
  box-sizing: border-box; }


blockquote {
  background: #f8f6f2;
  border-radius: 20px;
  display: flex;
  align-items: center;
  padding: 37px 30px 27px 40px;
  color: #0a2e6e;
  font-size: 18px;
  margin: 0 0 36px;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    left: 40px;
    top: 50%;
    transform: translate(0,-50%);
    width: 70px;
    height: 72px;
    background: url("../img/dialog.png") no-repeat center {
      size: contain; } }
  p {
    padding: 0 0 0 102px; } }

.table-wrapper {
  width: 100%; }

table {
  width: 100%;
  border: 1px solid rgba(0, 160, 203, 0.39);
  border-radius: 10px;
  background-color: #ffffff;
  overflow: hidden;
  margin: 0 0 48px;
  border-collapse: separate;
  tr {
    td, th {
      padding: 10px 0; } } }


.table-head, .table-body {
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  color: $text;
  div {
    box-sizing: border-box;
    padding: 13px 19px; }
  .quantity {
    width: 90px;
    text-align: center; }
  .name {
    width: 60%;
    padding: 0 19px;
    text-align: left;
 }    //min-height: 40px
  .total {
    width: 25%;
    text-align: center; } }

.table-body {
  &:nth-child(even) {
    background-color: #f4f5f8; } }


.subscribe-wrapper {
  margin: 50px 0;
  border-radius: 20px;
  background-color: $bg;
  width: 100%;
  padding: 39px 40px;
  box-sizing: border-box;
  .sing-up, p {
    color: $text3;
    font-size: 18px;
    font-family: 'BanderaPro-Bold', sans-serif; }
  .title {
    color: #fff;
    margin: 10px 0 19px;
    border: none;
    padding: 0;
    font-size: 30px;
    font-family: 'BanderaPro-Bold', sans-serif; }
  .total {
    color: $green;
    font-size: 18px;
    font-family: 'BanderaPro-Bold', sans-serif;
    margin: 0 0 30px; } }

.form-subscribe {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 0 29px;
  label {
    width: 100%;
    margin: 0 0 10px;
    position: relative;
    height: 50px;
    border-radius: 10px;
    background-color: #ffffff;
    .icon {
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translate(0,-50%); }
    input {
      width: 100%;
      border-radius: 10px;
      height: 100%;
      font-size: 14px;
      color: $text;
      padding: 0 44px;
      box-sizing: border-box;
      &::placeholder {
        color: $text; } } }
  button {
    width: 100%;
    height: 50px;
    border-radius: 10px;
    background-color: $text3;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $text;
    font-size: 14px;
    font-family: 'BanderaProMedium', sans-serif;
    &:hover {
      background: $blue;
      color: #000; } } }

.good-to-know {
  margin: 0 0 50px;
  width: 100%;
  border-radius: 20px;
  background-color: $bg1;
  box-sizing: border-box;
  padding: 38px 45px 36px 50px;
  .title {
    margin: 0 0 29px;
    color: $blue;
    font-size: 12px;
    border: none;
    padding: 0; }
  .links {
    margin: 0 0 30px;
    color: $text;
    font-size: 18px;
    font-family: 'Lora', sans-serif;
    &:hover {
      text-decoration: underline; } }
  .description {
    color: $text;
    font-size: 14px; } }


.title-h3 {
  font-size: 28px;
  font-family: 'Lora', sans-serif;
  margin: 0 0 25px;
  width: fit-content;
  color: $text; }

p {
  .links {
    display: inline;
    text-decoration: underline;
    color: $bg;
    &:hover {
      color: $text; } } }

.list-check {
  margin: 20px 0;
  width: 100%;
  color: $text;
  li {
    position: relative;
    padding: 0 0 0 30px;
    margin: 0 0 15px;
    &:last-child {
      margin: 0 0 0 30px; }
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(0,-50%);
      width: 15px;
      height: 15px;
      background: url("../img/check.png") no-repeat center {
        size: cover; } } } }

.list-number {
  margin: 35px 0;
  counter-reset: section;
  list-style-type: none;
  li {
    position: relative;
    padding: 0 0 0 33px;
    margin: 0 0 10px;
    color: $text;
    &:before {
      position: absolute;
      left: 0;
      top: 0;
      content: counters(section, ".") " ";
      counter-increment: section;
      background-color: $bg;
      border-radius: 50%;
      width: 23px;
      height: 23px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 14px;
      font-family: 'Lora', sans-serif;
      font-weight: bold; } } }

.list-doc {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  li {
    a {
      margin: 0 30px 0 0;
      padding: 0 0 0 23px;
      box-sizing: border-box;
      text-decoration: underline;
      font-size: 14px;
      &:hover {
        text-decoration: none;
        color: $text; } }
    .pdf {
      color: #f1523e;
      background: url("../img/pdf.png") no-repeat left center {
        size: 13px; } }
    .doc {
      color: #0271b6;
      background: url("../img/doc.png") no-repeat left center {
        size: 13px; } }
    .xls {
      color: #57b602;
      background: url("../img/xml.png") no-repeat left center {
        size: 13px; } } } }

.like-article-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  border-radius: 10px;
  background-color: #f4f5f8;
  padding: 18px 50px;
  font-family: 'BanderaProMedium', sans-serif;
  margin: 49px 0; }

.like-box {
  display: flex;
  align-items: center;
  border-radius: 10px;
  background-color: $bg;
  color: #ffffff;
  font-size: 14px;
  font-family: 'BanderaProMedium', sans-serif;
  padding: 7px 16px 11px;
  margin: 0 10px 0 0;
  p {
    margin: 0 6px 0 11px;
    color: #ffffff; }
  .text {
    font-size: 14px;
    color: #ffffff;
    opacity: .3; } }

.dislike-box {
  display: flex;
  align-items: center;
  background-color: #898e9e;
  border-radius: 10px;
  padding: 10px 16px 8px;
  .text {
    margin: 0 0 0 9px;
    font-size: 14px;
    color: #ffffff;
    opacity: .3; } }


.share-box {
  display: flex;
  align-items: center;
  p {
    font-size: 16px;
    margin: 0 20px 0 0; } }

.recommended-article, .discussions-wrapper {
  .title {
    width: fit-content;
    color: $text2;
    font-size: 18px;
    border-color: #5b92d1; }
  .article-preview {
    .title {
      color: $text;
      font-family: 'Lora', sans-serif; } } }

.discussion-user {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  .photo {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden; }
  .info-user {
    padding: 0 0 0 18px;
    width: calc(100% - 60px);
    .name-wrapper {
      display: flex;
      align-items: center;
      p {
        color: $text2;
        font-size: 14px;
        margin: 0 10px 0 0; } }
    .text {
      font-size: 16px;
      color: $text;
      margin: 7px 0 0 0; } } }

.reply-links {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: $text3;
  font-size: 9px;
  color: $text;
  padding: 3px 11px;
  &:hover {
    background: $bg;
    color: #fff; } }

.add-comment {
  margin: 50px  0;
  max-width: 730px; }

.form-comment {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  label {
    width: 48%;
    margin: 0 0 10px;
    &:nth-child(3) {
      width: 100%; }
    input {
      height: 40px;
      border: 1px solid rgba(198, 203, 212, 0.39);
      box-sizing: border-box;
      padding: 0 19px;
      color: rgba(#33305a, .5);
      font-size: 14px;
      width: 100%;
      font-family: $default-font;
      &::placeholder {
        color: rgba(#33305a, .5); } }
    textarea {
      font-family: $default-font;
      width: 100%;
      height: 70px;
      color: rgba(#33305a, .5);
      font-size: 14px;
      padding: 13px 19px;
      border: 1px solid rgba(198, 203, 212, 0.39);
      box-sizing: border-box;
      &::placeholder {
        color: rgba(#33305a, .5); } } }
  button {
    margin: 10px 0 0 0;

    padding: 17px 50px; } }


.btn-mobile-menu {
  display: none;
  flex-direction: column;
  align-items: center;
  span {
    margin: 2px 0;
    width: 25px;
    height: 3px;
    border-radius: 10px;
    background: $bg; } }

.btn-close {
  display: none;
  font-weight: bold;
  font-size: 20px;
  font-family: 'BanderaProMedium', sans-serif;
  border: 1px solid rgba(#fff, .4);
  padding: 5px 10px;
  color: #fff;
  margin: 10px 10px 10px auto;
  width: fit-content; }


@import "media";
// Всегда в конце
